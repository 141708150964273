import styled, { createGlobalStyle, css } from 'styled-components'
import Img from 'gatsby-image'
import { Media, hideSMUp, mbSize, typography } from '@renderbus/common/theme'
import transition from '../../../common/theme/transition'
import RoboBlackItalic from '../font/Robo-BlackItalic.ttf'
import RobotoBold from '../font/Roboto-Bold.ttf'
import RobotoRegular from '../font/Roboto-Regular.ttf'
import HeaderLogoImg from '../images/render-contest-2023/header-logo.png'
import LeftArrowIcon from '../images/render-contest-2023/arrow1@2x.png'
import LeftArrowHoverIcon from '../images/render-contest-2023/arrow1_hover@2x.png'
import RightArrowIcon from '../images/render-contest-2023/arrow2@2x.png'
import RightArrowHoverIcon from '../images/render-contest-2023/arrow2_hover@2x.png'
import UpArrowIcon from '../images/render-contest-2023/arrow_1@2x.png'
import DownArrowIcon from '../images/render-contest-2023/arrow_2@2x.png'

import NavStrip from '../images/render-contest/nav_strip.png'
import SidebarBg from '../images/render-contest/sidebar_bg.png'
import SignBtnBg from '../images/render-contest/sign_btn_bg.png'
import DownloadBtnBg from '../images/render-contest/download_btn_bg.png'
import DownloadBtnHover from '../images/render-contest/download_btn_hover.png'
import NavItemBg from '../images/render-contest/nav_item_bg.png'
import BackTop from '../images/render-contest/back_top.png'
import QrBg from '../images/render-contest/qr_bg.png'
import AwardActiveBg from '../images/render-contest/award_active_bg.png'
import AwardItemBg from '../images/render-contest/award_item_bg.png'
import AwardBg from '../images/render-contest/award_bg.png'
import AwardBgMobile from '../images/render-contest/award_bg_mobile.png'
import JudgeInfoBg from '../images/render-contest/judge_info_bg.png'
import JudgeInfoBgMobile from '../images/render-contest/judge_info_bg_mobile.png'
import JudgesBorderBg from '../images/render-contest/judges_border_bg.png'
import JudgesBorderBgMobile from '../images/render-contest/judges_border_bg_mobile.png'
import RcContentBg from '../images/render-contest/rc_content_bg.png'
import CriteriaBg from '../images/render-contest/criteria_bg.png'
import SupportBg from '../images/render-contest/support_bg.png'
import SupportDownload from '../images/render-contest/support_download.png'
import SupportDownloadHover from '../images/render-contest/support_download_hover.png'
import SupportLearn from '../images/render-contest/support_learn.png'
import SupportLearnHover from '../images/render-contest/support_learn_hover.png'
import SupportDownloadMb from '../images/render-contest/support_download_mb.png'
import SupportDownloadMbHover from '../images/render-contest/support_download_mb_hover.png'
import SupportLearnMb from '../images/render-contest/support_learn_mb.png'
import SupportLearnMbHover from '../images/render-contest/support_learn_mb_hover.png'
import PartnerBg from '../images/render-contest/partner_bg.png'
import PartnerBgMobile from '../images/render-contest/partner_bg_mobile.png'
import MenuIcon from '../images/render-contest/menu-icon.png'
import CloseIcon from '../images/render-contest/close-icon.png'
import Separate from '../images/render-contest/separate.png'
import awardTotalBg from '../images/render-contest/award-total-bg.png'
import awardTotalBgMb from '../images/render-contest/award-total-bg-mb.png'
import AwardsselectedBg from '../images/render-contest/awards-selected-bg.png'
import ScrollPointHover from '../images/render-contest/scroll-point-hover.png'

export const RCStyle = createGlobalStyle`
  @font-face {
    font-family: 'Robo-BlackItalic';
    src: url(${RoboBlackItalic});
    font-style: normal;
    font-weight: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Roboto-Bold';
    src: url(${RobotoBold});
    font-style: normal;
    font-weight: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'Roboto-Regular';
    src: url(${RobotoRegular});
    font-style: normal;
    font-weight: normal;
    font-display: fallback;
  }

  html {
    background-color: #010613;
  }
  .hide {
    display: none !important;
  }
  .hideInMobile {
    ${Media.lessThan(Media.small)} {
      display: none !important;
    }
  }
  .hideInPC {
    ${Media.greaterThan(Media.small)} {
      display: none !important;
    }
  }
`
export const BannerContainer = styled.div`
  padding-top: 60px;
`
export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`
export const PageFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 73px;
  width: 1200px;
  height: 63px;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  border-top: 1px solid #999999;
  color: #b0b6bc;
  img {
    margin: 10px 11px 0 5px;
    transform: translate(0, -50%);
  }
  a:hover {
    color: #5195f5;
  }
  ${Media.lessThan(Media.small)} {
    width: 92vw;
    height: auto;
    margin-top: 12.93vw;
    flex-direction: column;
    justify-content: space-around;
    font-size: 2.39vw;
    padding-top: 2.93vw;
    padding-bottom: 5.33vw;
    p {
      margin: auto;
    }
    img {
      margin: 1.6vw 3.33vw 0 5px;
      width: 1.86vw;
    }
  }
`
export const IndexSection = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  ${Media.lessThan(Media.small)} {
    &#rc-top {
      margin-top: 12vw;
    }
  }
`
export const VideoBanner = styled.div`
  width: 100%;
  z-index: -1;
  video {
    width: 100%;
    position: absolute;
  }
  img {
    width: 100%;
    position: absolute;
  }
`

export const ImgWrapper = styled(IndexSection)`
  position: relative;
  .hideInMobile {
    position: absolute;
    top: 275px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: -1;
    max-width: 100%;
  }
  ${Media.lessThan(Media.small)} {
    .hideInPC {
      display: block;
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      margin: auto;
      z-index: -1;
    }
  }
`
export const Slash = styled.div`
  display: inline-block;
  width: 8px;
  height: 29px;
  background: url(${Separate});
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 30px;
  ${Media.lessThan(Media.small)} {
    width: 4px;
    height: 10px;
    margin: 0 15px;
  }
`
export const TitleSection = styled(IndexSection)`
  align-items: center;
  color: #ffffff;
  h2 {
    margin: 0;
  }
  > img:first-child {
    position: relative;
    & + h2 {
      line-height: 58px;
      margin-top: -20px;
    }
  }
  h2 {
    display: block; // 兼容safari
    font-size: 40px;
    font-weight: 400;
    line-height: 58px;
    color: linear-gradient(180deg, #ffffff 0%, #a0e1fb 100%);
    background: linear-gradient(180deg, #ffffff 0%, #a0e1fb 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 8.13vw;
    > img:first-child {
      width: 15.2vw;
      /* height: 14.73vw; */
      & + h2 {
        line-height: 6.13vw;
        margin-top: -3.26vw;
      }
    }
    h2 {
      font-size: 16px;
      line-height: 6.13vw;
    }
  }
`
export const RCWordsSection = styled(TitleSection)`
  /* margin-top: -370px; */
  /* background: linear-gradient(transparent, #010613); */
  h2 {
    background-image: linear-gradient(180deg, #ffffff 0%, #a0befb 99%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 40px;
    font-weight: 400;
  }
  .words-container {
    color: #d2e7ff;
    margin-top: 16px;
    font-size: 16px;
    text-align: center;
    z-index: 1;
    .words-group {
      margin-bottom: 16px;
    }
    .word {
      line-height: 28px;
      margin: 0;
    }
  }
  ${Media.lessThan(Media.small)} {
    /* margin-top: -10vw; */
    h2 {
      font-size: 5.33vw;
    }
    .words-container {
      margin-top: 2.74vh;
      text-align: center;
      z-index: 1;
      .words-group {
        margin-bottom: 4.26vw;
      }
      .word {
        font-size: 3.2vw;
        line-height: 6.4vw;
        margin: 0;
      }
    }
  }
`
export const RCResultSection = styled(TitleSection)`
  margin-top: -99vh;
  ${Media.lessThan(Media.small)} {
    width: 92.13vw;
    margin: auto;
  }
`
export const RCDateSection = styled(TitleSection)`
  margin-top: 140px;
  .dates-container {
    margin-top: 60px;
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 12vw;
    .dates-container {
      margin-top: 8vw;
      img {
        width: 91.47vw;
      }
    }
  }
`
export const RCGroupSection = styled(TitleSection)`
  margin-top: 100px;
  .group-container {
    position: relative;
    margin-top: 60px;
    .curve {
      position: absolute;
      left: 40px;
      top: 76px;
    }
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 16vw;
    .hideInPC {
      margin-top: 8.53vw;
      width: 82.4vw;
    }
  }
`
export const AwardType = styled.span`
  position: relative;
  width: 231px;
  margin-bottom: 20px;
  height: 69px;
  line-height: 69px;
  font-size: 18px;
  font-weight: 700;
  background: ${(p) =>
    p.isActive
      ? `linear-gradient(180deg, #FFFFFF 0%, #DAF4FF 56%, #7ED6FA 76%, #7ED6FA 100%)`
      : `#FFFFFF`};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
  text-align: center;
  user-select: none;
  ::before {
    display: inline;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    content: '';
    background: ${(p) => (p.isActive ? `url(${AwardActiveBg})` : `url(${AwardItemBg})`)};
    z-index: -1;
    background-repeat: no-repeat;
    left: 0;
    top: 0;
    background-position: center;
  }
  ${Media.lessThan(Media.small)} {
    width: 31vw;
    margin-bottom: 5px;
    height: 8.53vw;
    line-height: 8.53vw;
    font-size: 10px;
    font-weight: 700;
    background: ${(p) =>
      p.isActive
        ? `linear-gradient(180deg, #FFFFFF 0%, #DAF4FF 56%, #7ED6FA 76%, #7ED6FA 100%)`
        : `#FFFFFF`};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    cursor: pointer;
    text-align: center;
    user-select: none;
    ::before {
      display: inline;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      content: '';
      background: ${(p) => (p.isActive ? `url(${AwardActiveBg})` : `url(${AwardItemBg})`)};
      background-size: 100%;
      z-index: -1;
      background-repeat: no-repeat;
      left: 0;
      top: 0;
      background-position: center;
    }
  }
`
export const RCAwardSection = styled(TitleSection)`
  margin-top: 100px;
  .award-container {
    display: flex;
    margin-top: 40px;
    max-width: 960px;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 66px;
    row-gap: 33px;
    margin-bottom: 170px;
  }
  .special-award {
    margin-top: 60px;
    width: 1200px;
    height: max-content;
  }
  .award-type {
    display: flex;
    justify-content: center;
    gap: 28px;
  }
  .award {
    height: 668px;
    display: flex;
    flex-direction: column;
    position: relative;
    background: url(${AwardBg});
    padding: 60px 50px;
    justify-content: center;
    &.zeno,
    &.wanghua {
      flex-direction: row;
      align-items: center;
      .img-list {
        width: unset;
      }
    }
    &.zeno .award-content .text {
      max-width: 310px;
    }
    .img-list {
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin-bottom: 20px;
      img {
        width: 260px;
        height: 316px;
      }
    }
    .text-content {
      display: flex;
      min-width: 531px;
      .text-item {
        margin: 0 40px;
      }
      p {
        margin: 0;
      }
      .award-title {
        margin-bottom: 20px;
      }
      .title {
        font-size: 14px;
        font-weight: 900;
        line-height: 30px;
      }
      .title-content {
        font-size: 16px;
        color: #acd6ff;
        line-height: 27px;
      }
      .total-amount {
        display: block;
        font-size: 18px;
        background: linear-gradient(180deg, #ffe9ca 24%, #ffb23e 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: bold;
        line-height: 26px;
      }
      .award-content {
        line-height: 30px;
        display: flex;
        gap: 30px;
      }
      .amount {
        color: #e8c28a;
      }
      .text {
        font-size: 16px;
        color: #acd6ff;
      }
    }
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 16vw;
    .special-award {
      margin-top: 8vw;
      width: 96vw;
    }
    .award-type {
      width: 100%;
      flex-wrap: wrap;
      gap: 1px;
      justify-content: start;
    }
    .award-container-mobile {
      width: 100%;
      margin-bottom: 16vw;
      position: relative;
      .left-icon,
      .right-icon {
        position: absolute;
        width: 24px;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 3;
      }
      .left-icon {
        left: 10px;
      }
      .right-icon {
        right: 10px;
      }
    }
    .award {
      width: 91vw;
      margin: auto;
      display: flex;
      flex-direction: column;
      position: relative;
      background: url(${AwardBgMobile});
      background-size: 100% 100%;
      padding: 11.73vw 2.4vw;
      justify-content: flex-start;
      &.zeno .award-content .text {
        max-width: 50vw;
      }
    }
  }
`
export const AwardContentMobile = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .img-list-mobile {
    position: relative;
  }
  .left-icon,
  .right-icon {
    position: absolute;
    width: 24px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 3;
  }
  .left-icon {
    left: 0;
  }
  .right-icon {
    right: 0;
  }
  .img-award {
    max-width: 260px;
    width: 69.33vw;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .img-and-dot {
    position: relative;
  }
  .dot-list {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    justify-content: center;
    gap: 15px;
    .dot {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: rgba(244, 198, 149, 0.2);
    }
    .active {
      background-color: rgba(250, 204, 158, 1);
    }
  }
  .text-item {
    margin: 6.4vw 10px;
  }
  p {
    margin: 0;
  }
  .title {
    font-size: 4.27vw;
    font-weight: 700;
    line-height: 6.13vw;
  }
  .title-content {
    font-size: 3.2vw;
    color: #acd6ff;
    margin: 2.13vw 0 5vw;
    line-height: 24px;
  }
  .total-amount {
    font-size: 4.27vw;
    font-weight: bold;
    background: linear-gradient(180deg, #ffe9ca 24%, #ffb23e 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .award-content {
    line-height: 23px;
    display: flex;
    font-size: 3.2vw;
    gap: 2.13vw;
  }
  .amount {
    color: #e8c28a;
  }
  .text {
    font-size: 12px;
    color: #acd6ff;
  }
`
export const AwardItem = styled.div`
  width: 275px;
  text-align: center;
  padding: 0 10px;
  position: relative;
  img {
    position: absolute;
    left: 0;
    z-index: -1;
  }
  p {
    margin: 0;
  }
  .title {
    font-size: ${typography.h2};
    font-weight: 700;
    line-height: 43px;
    background: ${(p) => p.titleColor};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 32px 0 20px;
  }
  .num {
    width: 60px;
    height: 24px;
    font-size: ${typography.text};
    color: #ffffff;
    line-height: 23px;
    border-radius: 100px;
    border: 1px solid #c8d5f4;
    margin: auto;
  }
  .line {
    width: 141px;
    height: 1px;
    border: 1px solid;
    border-image: ${(p) => p.lineColor};
    margin: 1.3rem auto 0.75rem;
  }
  .total {
    font-size: 2.5rem;
    font-weight: 700;
    height: 3rem;
    line-height: 3rem;
    background: ${(p) => p.titleColor};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    span {
      font-size: ${typography.text};
    }
  }
  .amount {
    font-size: ${typography.text};
    font-weight: 500;
    line-height: 23px;
    margin-top: 8px;
    color: ${(p) => p.otherAmountColor};
    span {
      font-size: 10px;
    }
  }
  .award-item {
    font-size: ${typography.textSmall};
    font-weight: 400;
    color: #91aadc;
    line-height: 16px;
    span {
      font-size: 10px;
    }
  }
  &.first,
  &.second {
    .award-item .award-item-0 {
      font-size: 12px !important;
    }
  }
  &.fourth,
  &.fifth {
    .title {
      margin-top: 65px;
    }
  }
  &.fifth .award-item:last-child {
    color: #87b2f0;
  }
  ${Media.lessThan(Media.small)} {
    max-width: 275px;
    margin: auto;
    min-height: 980px;
    padding-top: 20px;
    .title {
      font-size: 30px;
    }
    &.fourth,
    &.fifth {
      img {
        width: 100%;
      }
      .title {
        margin-top: 65px;
      }
    }
    .dot-list {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      gap: 20px;
      .dot {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: rgba(145, 170, 220, 0.2);
      }
      .active {
        background-color: rgba(145, 170, 220, 1);
      }
    }
  }
`
export const LeftArrow = styled.button`
  background: url(${LeftArrowIcon}) no-repeat;
  background-size: 100%;
  width: 40px;
  height: 80px;
  cursor: pointer;
  border: unset;
  &:hover {
    background: url(${LeftArrowHoverIcon}) no-repeat;
    background-size: 100%;
  }
  ${Media.lessThan(Media.small)} {
    width: 5.33vw;
    height: 10.66vw;
  }
`
export const RightArrow = styled.button`
  background: url(${RightArrowIcon}) no-repeat;
  background-size: 100%;
  width: 40px;
  height: 80px;
  cursor: pointer;
  border: unset;
  &:hover {
    background: url(${RightArrowHoverIcon}) no-repeat;
    background-size: 100%;
  }
  ${Media.lessThan(Media.small)} {
    width: 5.33vw;
    height: 10.66vw;
  }
`
export const Profile = styled.div`
  z-index: 9;
  height: 100%;
  width: ${(p) =>
    !p.isActive ? (p.width ? p.width : '240px') : p.hoverWidth ? p.hoverWidth : '340px'};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: ${(p) =>
    p.isActive ? `url(${p.imgHover})} no-repeat` : p.img ? `url(${p.img}) no-repeat` : '#425FCB'};
  background-size: 100%;
  border-radius: 30px 10px 10px 10px;
  font-size: 14px;
  position: relative;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  &.expand {
    .short {
      display: ${(p) => p.isActive && 'none'};
    }
    .total {
      display: ${(p) => p.isActive && 'unset !important'};
    }
  }
  .name {
    font-size: 24px;
    font-weight: bold;
    line-height: 36px;
    margin: 0 0 0 20px;
  }
  .intros {
    min-height: 50px;
    margin-bottom: 22px;
    display: flex;
    flex-direction: column;
    p {
      margin: unset;
      font-size: 14px;
      font-weight: 400;
      margin-left: 21px;
      margin-right: 21px;
      line-height: 24px;
    }
  }
  ${Media.lessThan(Media.small)} {
    width: ${(p) =>
      p.isActive ? (p.hoverWidth ? p.hoverWidth : '45.33vw') : p.mbWidth ? p.mbWidth : '32vw'};
    .name {
      font-size: 3.19vw;
      font-weight: bold;
      margin: 0 0 1.46vw 3.86vw;
    }
    .intros {
      min-height: 6.66vw;
      margin-bottom: unset;
      p {
        margin: unset;
        font-size: 1.86vw;
        font-weight: 400;
        margin-left: 3.86vw;
        margin-right: 1vw;
        line-height: 3.19vw;
      }
    }
  }
`
export const ProfileContainer = styled.div`
  margin-top: 60px;
  width: 1218px;
  /* height: 768px; */
  height: 968px;
  border-radius: 4px;
  opacity: 1;
  background: url(${JudgesBorderBg});
  background-size: 100% 100%;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .judge-info {
    display: flex;
    width: 1201px;
    height: 366px;
    z-index: -1;
    background: url(${JudgeInfoBg});
    background-size: 100% 100%;
    padding: 12px 0 0 50px;
    > div:first-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 30px;
    }
    p {
      margin: 0;
    }
    .name {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
    }
    .english-name {
      font-size: 20px;
      font-weight: 400;
      line-height: 32px;
    }
    .intro-content {
      margin-top: 20px;
    }
    .intro {
      font-size: 14px;
      line-height: 26px;
      color: #dcdcdc;
      width: 523px;
    }
  }
  .avater-list {
    display: flex;
    flex-wrap: wrap;
    width: 1200px;
    margin-left: 3px;
    img {
    }
    .judge-intro-item {
      position: relative;
      img {
        width: 200px;
      }
    }
    .intro-text {
      position: absolute;
      z-index: 2;
      bottom: 0;
      padding: 0 16px;
      p {
        margin: auto;
      }
      .name {
        font-weight: 700;
        font-size: 14px;
      }
      .position {
        font-size: 14px;
        font-weight: 400;
        color: #dcdcdc;
        height: 51px;
        line-height: 22px;
      }
    }
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 8vw;
    /* width: 92vw; */
    width: 94vw;
    height: unset;
    border-radius: 4px;
    opacity: 1;
    background: url(${JudgesBorderBgMobile});
    background-size: 100% 100%;
    .judge-info-mobile {
      width: 98%;
      z-index: -1;
      background: url(${JudgeInfoBgMobile});
      background-size: 100% 100%;
      position: relative;
      margin-top: 15px;
      padding: 10px;
      > div:first-child {
        display: flex;
        align-items: center;
        justify-content: space-around;
        img {
          max-width: 137px;
        }
      }
      p {
        margin: 0;
      }
      .name {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
      }
      .english-name {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
      }
      .intro {
        font-size: 10px;
        line-height: 18px;
        color: #dcdcdc;
      }
    }
    .avater-list {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-left: 3px;
      margin-bottom: 20px;
      .judge-intro-item {
        position: relative;
        img {
          width: 30.4vw;
        }
      }
      .intro-text {
        position: absolute;
        z-index: 2;
        bottom: 0;
        left: 0;
        padding: 0 4px;
        p {
          margin: auto;
        }
        .name {
          font-size: 2.66vw;
        }
        .position {
          font-size: 2.66vw;
          height: 10.73vw;
          line-height: 3.46vw;
        }
      }
    }
  }
`
export const RCJudgesSection = styled(TitleSection)`
  margin: 100px auto 0;
  ${Media.lessThan(Media.small)} {
    margin-top: 16vw;
  }
`
export const LeftTagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: max-content;
  margin-top: 35px;
  margin-bottom: 164px;
  user-select: none;
`
export const Tag = styled.span`
  text-align: center;
  position: relative;
  left: ${(p) => p.left || '-10px'};
  cursor: pointer;
  border: ${(p) => (p.isActive ? '1px solid transparent' : 'unset')};
  border-right: none;
  border-image: ${(p) =>
    p.isActive ? 'linear-gradient(90deg, #5195F5, transparent) 10 10' : 'unset'};
  background: ${(p) =>
    p.isActive ? 'linear-gradient(90deg, #425FCB 0%, transparent 100%)' : 'unset'};
  font-weight: ${(p) => (p.isActive ? 'bold' : '400')};
  color: ${(p) => (p.isActive ? '#FFFFFF' : '#DDDDDD')};
  height: ${(p) => (p.isActive ? '41px' : '36px')};
  width: 181px;
  line-height: ${(p) => (p.isActive ? '41px' : '36px')};
  font-size: ${(p) => (p.isActive ? '18px' : '16px')};
  ${Media.lessThan(Media.small)} {
    height: ${(p) => (p.isActive ? '8.13vw' : '4.79vw')};
    width: 24.13vw;
    line-height: ${(p) => (p.isActive ? '8.13vw' : '4.79vw')};
    font-size: ${(p) => (p.isActive ? '3.19vw' : '2.66vw')};
  }
`
export const RCContentSection = styled(TitleSection)`
  margin-top: 100px;
  .content {
    display: flex;
    width: 1203px;
    min-height: 422px;
    margin-top: 60px;
    background: url(${RcContentBg});
    background-repeat: no-repeat;
    align-items: center;
    justify-content: center;
  }
  h2 ~ h2 {
    font-size: 32px;
    margin-top: 70px;
  }
  .video-container {
    width: 518px;
    height: 300px;
    padding: 1px;
    background: #f4d096;
    position: relative;
    cursor: pointer;
    img {
      width: 90px;
      height: 90px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    video {
      width: 100%;
      height: 100%;
    }
  }
  .text-container {
    display: flex;
    flex-direction: column;
    width: 564px;
    margin-left: 32px;
    p {
      font-size: 28px;
      font-weight: bold;
      color: #ffffff;
      line-height: 36px;
      margin: 0 0 32px 0;
    }
    span {
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      margin-bottom: 12px;
      letter-spacing: 1px;
    }
  }
  a.donwload {
    width: 258px;
    height: 70px;
    background: url(${DownloadBtnBg});
    background-repeat: no-repeat;
    font-size: 16px;
    font-weight: 700;
    color: #17101b;
    line-height: 72px;
    text-align: center;
    margin-top: 20px;
    font-family: 'Source Han Sans CN';
    &:hover {
      background: url(${DownloadBtnHover});
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    img {
      display: inline-block;
      width: 17px;
      height: 17px;
    }
  }
  .criter-content {
    margin-top: 60px;
    width: 1200px;
    height: 273px;
    display: flex;
    background: url(${CriteriaBg});
    justify-content: space-between;
    align-items: center;
    .criter-item {
      width: 259px;
      height: 193px;
      background: linear-gradient(180deg, #010613 41%, #010613 55%, #121d47 100%);
      border-radius: 4px;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 18px 13px;
      ::before {
        display: inline;
        position: absolute;
        width: 261px;
        height: 195px;
        top: -1px;
        content: '';
        border-radius: 4px;
        background: linear-gradient(
          225deg,
          rgba(244, 208, 150, 1) 21%,
          rgba(124, 185, 253, 1) 55%,
          rgba(124, 185, 253, 1) 100%
        );
        z-index: -1;
      }
      img {
        margin: 9px 0 12px;
      }
      p {
        font-size: 16px;
        line-height: 23px;
        margin: 0;
      }
      p:first-child {
        font-size: 26px;
        font-weight: 500;
        line-height: 38px;
      }
    }
  }
  .support-content {
    width: 1203px;
    height: 504px;
    background: url(${SupportBg});
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 46px 30px;
    gap: 24px;
    .support-item {
      width: 357px;
      height: 179px;
      background: linear-gradient(
        226deg,
        rgba(235, 245, 255, 0.2) 0%,
        rgba(79, 162, 240, 0.2) 24%,
        rgba(244, 208, 150, 0.2) 100%
      );
      border-radius: 4px;
      padding: 23px 15px 0;
    }
    p {
      font-size: 16px;
      margin: 12px 0 20px;
    }
    .btn-group {
      border-top: 2px dotted rgba(255, 255, 255, 0.2);
      display: flex;
      line-height: 70px;
      color: #599bff;
      font-size: 14px;
      justify-content: space-around;
      a:hover {
        color: #b4985e;
      }
      a:first-child::after,
      a:last-child::after,
      a:first-child:hover::after,
      a:last-child:hover::after {
        display: inline-block;
        content: '';
        width: 15px;
        height: 15px;
        margin: 0 0 -3px 3px;
      }
      a:first-child {
        ::after {
          background: url(${SupportLearn});
        }
        &.style3d {
          ::after {
            background: url(${SupportDownload});
          }
        }
      }
      a:last-child {
        ::after {
          height: 15px;
          background: url(${SupportDownload});
        }
      }
      a:first-child:hover {
        ::after {
          background: url(${SupportLearnHover});
        }
        &.style3d {
          ::after {
            background: url(${SupportDownloadHover});
          }
        }
      }
      a:last-child:hover {
        ::after {
          background: url(${SupportDownloadHover});
        }
      }
    }
  }
  p.sources-intro {
    margin: 24px 0 0 0;
    font-size: 14px;
    a {
      color: #599bff;
    }
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 16vw;
    .content {
      flex-direction: column;
      width: 91.47vw;
      min-height: 592px;
      margin-top: 8vw;
      background: url(${AwardBgMobile});
      background-size: 100% 100%;
      padding: 8vw 1.33vw 5.33vw 0;
    }
    h2 ~ h2 {
      font-size: 16px;
      margin-top: 13.6vw;
    }
    .video-container {
      width: 86.2vw;
      height: 50.4vw;
      position: relative;
      padding: 1px 3px;
      img {
        width: 13.97vw;
        height: 13.97vw;
      }
    }
    .text-container {
      width: 82.53vw;
      margin: unset;
      p {
        font-size: 5.33vw;
        font-weight: bold;
        color: #ffffff;
        line-height: 6.13vw;
        margin: 5.33vw 0 3.2vw 0;
      }
      span {
        font-size: 3.2vw;
        font-weight: 400;
        color: #ffffff;
        line-height: 5.33vw;
        margin-bottom: 8px;
      }
    }
    a.donwload {
      max-width: 258px;
      max-height: 70px;
      width: 64vw;
      height: 18.13vw;
      font-size: 16px;
      font-weight: bold;
      text-align: center;
      margin-top: 7.59vw;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      line-height: 19.13vw;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        width: 64vw;
        height: 18.13vw;
      }
    }
    .criter-content {
      margin-top: 8vw;
      width: 93vw;
      height: unset;
      display: flex;
      flex-wrap: wrap;
      background: unset;
      justify-content: center;
      align-items: center;
      gap: 3.47vw;
      .criter-item {
        width: 44vw;
        height: 32.8vw;
        border-radius: 2px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2.76vw;
        ::before {
          display: inline;
          position: absolute;
          width: 44.5vw;
          height: 33.2vw;
          top: -1px;
          content: '';
          border-radius: 4px;
          background: linear-gradient(
            225deg,
            rgba(244, 208, 150, 1) 21%,
            rgba(124, 185, 253, 1) 55%,
            rgba(124, 185, 253, 1) 100%
          );
          z-index: -1;
        }
        img {
          width: 19.73vw;
          margin: 2.13vw 0;
        }
        p {
          font-size: 2.67vw;
          line-height: 3.73vw;
          margin: 0;
        }
        p:first-child {
          font-size: 4.27vw;
          font-weight: 500;
          line-height: 6.13vw;
        }
      }
    }
    .support-content {
      width: 94vw;
      height: unset;
      background: unset;
      margin-top: 8vw;
      padding: 0;
      gap: 5.3vw;
      .support-item {
        width: 43vw;
        height: unset;
        border-radius: 2px;
        padding: 2.67vw 2vw 0;
        img {
          height: 3.72vw;
        }
      }
      p {
        font-size: 2.67vw;
        margin: 1.33vw 0 2.4vw;
        min-height: 9.6vw;
      }
      .btn-group {
        line-height: 8vw;
        font-size: 2.13vw;
        text-align: left;
        display: block;
        a {
          display: flex;
          line-height: 8vw;
          align-items: center;
        }
        a:first-child::after,
        a:last-child::after,
        a:first-child:hover::after,
        a:last-child:hover::after {
          display: inline-block;
          content: '';
          width: 10px;
          height: 10px;
          margin: 0 0 0 2px;
        }
        a:first-child {
          ::after {
            background: url(${SupportLearnMb});
            background-size: 100% 100%;
          }
          &.style3d {
            ::after {
              background: url(${SupportDownloadMb});
              background-size: 100% 100%;
            }
          }
        }
        a:last-child {
          ::after {
            background: url(${SupportDownloadMb});
            background-size: 100% 100%;
          }
        }
        a:first-child:hover {
          ::after {
            background: url(${SupportLearnMbHover});
            background-size: 100% 100%;
          }
          &.style3d {
            ::after {
              background: url(${SupportDownloadMbHover});
              background-size: 100% 100%;
            }
          }
        }
        a:last-child:hover {
          ::after {
            background: url(${SupportDownloadMbHover});
            background-size: 100% 100%;
          }
        }
      }
    }
    p.sources-intro {
      margin: 8vw 0;
      font-size: 3.2vw;
      max-width: 90vw;
    }
  }
`
export const RCDesignSection = styled(TitleSection)`
  margin-top: 100px;
  .design-container {
    margin-top: 60px;
    width: 1200px;
    height: max-content;
    .desin-tag {
      display: flex;
      justify-content: center;
      gap: 28px;
    }
    .design-content {
      width: 1200px;
      height: 411px;
      background: url(${SupportBg});
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 55px;
      line-height: 36px;
      p {
        margin: 0;
      }
    }
    .qa-content {
      height: 100%;
      overflow: auto;
      padding-right: 30px;
      scrollbar-width: 10px;
      scrollbar-track-color: #193c69;
      ::-webkit-scrollbar {
        width: 10px;
      }
      ::-webkit-scrollbar-track {
        background-color: #193c69;
        border-radius: 100px;
      }
      ::-webkit-scrollbar-thumb {
        background-color: #2063b6;
        border-radius: 100px;
      }
    }
    .real-time {
      width: fit-content;
      height: 39px;
      font-size: 16px;
      background: rgba(21, 99, 187, 0.3);
      padding: 0 10px;
      img {
        margin-right: 10px;
      }
      a {
        color: #599bff;
        text-decoration: underline;
      }
    }
    .qa-item {
      margin-top: 20px;
      .question {
        font-size: 16px;
        line-height: 23px;
      }
      .answer {
        font-size: 14px;
        color: #acd6ff;
        line-height: 20px;
        margin-top: 4px;
      }
    }
    .other-item {
      margin: 0;
      line-height: 36px;
      display: flex;
      div:first-child {
        width: 20px;
        flex: none;
      }
    }
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 16vw;
    .design-container {
      margin-top: 8vw;
      width: 92vw;
      height: max-content;
      .desin-tag {
        gap: 3px;
      }
      .design-content {
        width: 100%;
        height: 104vw;
        background: url(${AwardBgMobile});
        background-size: 100% 100%;
        background-repeat: no-repeat;
        padding: 6.67vw 3.2vw;
        p {
          margin: 0;
        }
      }
      .qa-content {
        height: 100%;
        overflow: auto;
        padding-right: 10px;
        ::-webkit-scrollbar {
          width: 5px;
        }
      }
      .real-time {
        width: fit-content;
        height: max-content;
        line-height: 7.2vw;
        font-size: 3.2vw;
        padding: 0 5px;
      }
      .qa-item {
        margin-top: 4.27vw;
        .question {
          font-size: 3.73vw;
          line-height: 5.33vw;
        }
        .answer {
          font-size: 3.2vw;
          line-height: 4.53vw;
        }
      }
      .other-item {
        margin: 0;
        font-size: 3.2vw;
        line-height: 5.87vw;
        div:first-child {
          width: 15px;
          flex: none;
        }
      }
    }
  }
`
export const RCAboutSection = styled(TitleSection)`
  margin-top: 100px;
  .about-tag {
    display: flex;
    justify-content: center;
    gap: 28px;
  }
  .about-container {
    margin-top: 66px;
  }
  .about-rc {
    width: 1200px;
    height: 411px;
    background: url(${SupportBg});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 55px;
    line-height: 36px;
    p {
      margin: 0;
    }
  }
  .talk-content {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-right: 60px;
      width: 161px;
      height: 161px;
    }
    span {
      color: #f9e87c;
      font-weight: 700;
    }
  }
  .other-content {
    margin: 0;
    line-height: 36px;
    display: flex;
    div:first-child {
      width: 20px;
      flex: none;
    }
    &.about-intro {
      display: unset;
      div:first-child {
        width: unset;
      }
    }
  }
  a {
    width: 258px;
    height: 70px;
    background: url(${DownloadBtnBg});
    background-repeat: no-repeat;
    font-size: 16px;
    font-weight: 700;
    color: #17101b;
    line-height: 72px;
    text-align: center;
    margin: 87px 0;
    &:hover {
      background: url(${DownloadBtnHover});
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }
  .partner-container {
    background: url(${PartnerBg});
    background-size: cover;
    background-position: center;
    width: 100%;
    text-align: center;
    padding-top: 110px;
    .partner {
      width: 1420px;
      margin: auto;
    }
    .partner-name {
      font-size: 18px;
      margin: 40px 0 20px;
      color: #55a4ec;
      font-weight: 700;
    }
    .partner-content {
      display: flex;
      gap: 24px;
      justify-content: center;
      flex-wrap: wrap;
      &.support-unit {
        width: 1200px;
        margin: auto;
      }
      &.liangxiang .partner-item {
        width: 588px;
        height: 381px;
        img {
          width: 514px;
          height: unset;
        }
      }
    }
    .partner-item {
      width: 180px;
      height: 60px;
      font-size: 14px;
      background: rgba(115, 159, 247, 0.1);
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 148px;
        height: 36px;
      }
    }
  }
  ${Media.lessThan(Media.small)} {
    margin-top: 16vw;
    .about-tag {
      gap: 3px;
    }
    .about-container {
      width: 92vw;
      margin-top: 8vw;
    }
    .about-rc {
      width: 100%;
      height: 104vw;
      background: url(${AwardBgMobile});
      background-size: 100% 100%;
      background-repeat: no-repeat;
      padding: 6.67vw 3.2vw;
      line-height: 5.87vw;
      font-size: 3.2vw;
      p {
        margin: 0;
      }
    }
    .talk-content {
      height: 100%;
      flex-direction: column;
      text-align: center;
      gap: 5.33vw;
      img {
        margin-right: 0;
        width: 34.67vw;
        height: 34.67vw;
      }
    }
    .other-content {
      margin: 0;
      line-height: 5.87vw;
      display: flex;
      div:first-child {
        width: 15px;
        flex: none;
      }
      &.about-intro {
        display: unset;
        div:first-child {
          width: unset;
        }
      }
    }
    a {
      max-width: 258px;
      max-height: 70px;
      width: 64.26vw;
      height: 18.13vw;
      font-size: 16px;
      /* line-height: 19.13vw; */
      margin: 7.2vw 0 0;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      align-items: center;
      :hover {
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }
    }
    .partner-container {
      padding-top: 24vw;
      background: url(${PartnerBgMobile});
      background-size: auto;
      background-position: top center;
      background-repeat: no-repeat;
      width: 100%;
      text-align: center;
      .partner {
        width: 96vw;
        margin: auto;
      }
      .partner-name {
        font-size: 3.73vw;
        margin: 20px 0 10px;
        font-weight: 700;
      }
      .partner-content {
        display: flex;
        gap: 2.47vw;
        justify-content: center;
        flex-wrap: wrap;
        &.support-unit,
        &.thanks {
          width: unset;
          .partner-item {
            height: 15.47vw;
          }
        }
        &.liangxiang .partner-item {
          width: 90.66vw;
          height: 60.8vw;
          img {
            width: 85.06vw;
            height: unset;
          }
        }
        &.sponsor {
          justify-content: start;
          max-width: 91vw;
          margin: auto;
        }
      }
      .partner-item {
        width: 28.46vw;
        height: 11.14vw;
        font-size: 2.67vw;
        border-radius: 2px;
        img {
          width: 24vw;
          height: unset;
        }
      }
    }
  }
`
export const RCFormSection = styled(TitleSection)`
  margin: 59px 0 0 0;
  ${Media.lessThan(Media.small)} {
    margin: 7.86vw 0 0 0;
  }
`
export const RCSignFormContainer = styled.form`
  width: 1141px;
  border: 1px solid;
  font-size: 14px;
  border-image: linear-gradient(0deg, #283c78, #5195f5) 10 10;
  background: linear-gradient(0deg, #15145c 0%, rgba(75, 40, 110, 0.2) 100%);
  display: flex;
  flex-direction: column;
  aling-items: center;
  padding: 60px 0 78px 0;
  h2 {
    text-align: center;
    margin-bottom: 59px;
  }
  .qr-container {
    margin-top: 60px;
    display: flex;
    img {
      width: 120px;
      height: 120px;
      margin-left: 294px;
    }
    div {
      margin-left: 55px;
      p {
        margin: unset;
        font-size: 14px;
        color: #dddddd;
        line-height: 24px;
      }
    }
  }
  ${Media.lessThan(Media.small)} {
    width: 94.8vw;
    padding: 7.99vw 0 10.26vw 0;
    h2 {
      margin: 0 0 7.99vw 0;
      font-size: 3.99vw;
      line-height: 3.99vw;
    }
    .qr-container {
      margin-top: 8.13vw;
      img {
        width: 19.19vw;
        height: 19.19vw;
        margin-left: 6vw;
      }
      div {
        margin-left: 4vw;
        p {
          font-size: 2.39vw;
          line-height: 3.39vw;
        }
      }
    }
  }
`
export const RCSignForm = styled.div``
export const RCInput = styled.input`
  width: ${(p) => (p.width ? `${p.width}px` : '201px')};
  height: 31px;
  border: 1px solid #3e5abe;
  border-radius: 4px;
  outline-style: none;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  &:focus {
    border: 1px solid #5195f5;
  }
`
export const StudentCardContainer = styled.div`
  margin-left: 270px;
  position: relative;
  display: flex;
`
export const RCRadio = styled.input`
  cursor: pointer;
  opacity: 0;
  margin-left: ${(p) => `${p.left}px`};
  + span {
    position: relative;
    &::before {
      content: '';
      pointer-events: none;
      position: absolute;
      left: -33px;
      top: 50%;
      transform: translate(0, -50%);
      width: 22px;
      height: 22px;
      border: 2px solid #5195f5;
      border-radius: 50%;
      background: transparent;
    }
    &::after {
      content: '';
      pointer-events: none;
      position: absolute;
      left: -28px;
      top: 50%;
      transform: translate(0, -50%);
      width: 12px;
      height: 12px;
      border-radius: 50%;
    }
  }
  &:checked {
    + span::after {
      background: #5195f5;
    }
  }
  ${Media.lessThan(Media.small)} {
    + span {
      margin-left: 3.46vw !important;
    }
  }
`
export const RCRadioContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 400px;
  margin-left: ${(p) => `${p.left}px`};
  div {
    width: 50%;
    position: relative;
    span {
      margin-left: 15px;
    }
    ${RCInput} {
      position: absolute;
      border: none;
      border-bottom: 1px solid #999999;
      border-radius: unset;
    }
  }
  > ${RCRadio} {
    + span {
      margin-left: 14px;
      &::before {
        left: -32px;
      }
      &::after {
        left: -27px;
      }
    }
  }
`
export const RCOption = styled.span`
  cursor: pointer;
  color: ${(p) => (p.isActive ? '#5195F5' : '#DDDDDD')};
  background: ${(p) => p.isActive && '#2F323E'};
  line-height: 30px;
  font-size: 14px;
  padding-left: 74px;
  &::before {
    content: 'o ';
  }
  &:hover {
    background: #2f323e;
  }
  ${Media.lessThan(Media.small)} {
    font-size: 2.39vw;
    line-height: 3.99vw;
    padding-left: 9.86vw;
  }
`
export const RCOptionContainer = styled.div`
  display: ${(p) => (p.isActive ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  background: #252a3a;
  width: 100%;
  max-height: 280px;
  overflow: hidden;
  z-index: 5;
  .ps__rail-y {
    width: 10px;
    padding-left: 10px !important;
    background: transparent !important;
    .ps__thumb-y {
      width: 10px;
      padding-left: 10px !important;
      background-color: #666666;
    }
  }
  ${Media.lessThan(Media.small)} {
    max-height: 37.33vw;
  }
`
export const RCSelectIcon = styled.div`
  background: ${(p) =>
    p.isActive ? `url(${UpArrowIcon}) no-repeat` : `url(${DownArrowIcon}) no-repeat`};
  background-size: 100%;
  position: absolute;
  width: 18px;
  height: 11px;
  left: 16px;
  top: 50%;
  transform: translate(0, -50%);
  ${Media.lessThan(Media.small)} {
    width: 3vw;
    height: 1.83vw;
    left: 1.86vw;
  }
`
export const RCSelect = styled(RCInput)`
  position: relative;
  outline-style: none;
  width: 521px;
  height: 31px;
  line-height: 100%;
  border: 1px solid #3e5abe;
  border-radius: 4px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: unset;
  padding-left: 48px;
  &:focus {
    border: 1px solid #5195f5;
  }
  ${Media.lessThan(Media.small)} {
    width: 84.13vw;
    height: 6.79vw;
    font-size: 2.39vw;
    padding-left: 7.2vw;
  }
`
export const RCSelectContainer = styled.div`
  position: relative;
  display: inline-block;
`
export const RCLabel = styled.span`
  font-size: 14px;
  height: 100%;
  margin-left: ${(p) => `${p.left}px`};
  margin-right: ${(p) => `${p.right}px`};
  margin-top: ${(p) => `${p.top}px`};
  ${Media.lessThan(Media.small)} {
    all: unset;
    font-size: 2.66vw;
    + ${RCInput}, + ${RCSelect} {
      font-size: 2.39vw;
      height: 6.79vw;
    }
    &.name {
      margin: 0 3.06vw 0 8vw;
      + ${RCInput} {
        width: 66.8vw;
      }
    }
    &.phone {
      margin: 0 3.06vw 0 8vw;
      + ${RCInput} {
        width: 66.8vw;
        margin-top: 3.99vw;
      }
    }
    &.email {
      margin: 0 3.06vw 0 8vw;
      + ${RCInput} {
        width: 66.8vw;
        margin-top: 3.99vw;
      }
    }
    &.wechat {
      margin: 0 3.9vw 0 4.5vw;
      + ${RCInput} {
        width: 66.8vw;
        margin-top: 3.99vw;
      }
    }
    &.group {
      margin: 0 5vw 0 8vw;
      margin-top: 4.79vw;
      + ${RCRadioContainer} {
        all: unset;
        font-size: 2.66vw;
        margin-top: 4.79vw;
        display: flex;
        span.expert {
          margin-right: 9.86vw;
          + input {
            margin: 0;
          }
        }
      }
    }
    &.organization {
      margin: 0 0 0 5.33vw;
      + input {
        width: 84.13vw;
        margin-left: 5.33vw;
        margin-top: 2.53vw;
      }
    }
    &.position {
      margin: 0 0 2.53vw 5.33vw;
      + ${RCSelectContainer} {
        width: 84.13vw;
        margin-left: 5.33vw;
        margin-top: 2.53vw;
      }
    }
    &.business {
      margin: 0 0 2.53vw 5.33vw;
      + ${RCSelectContainer} {
        width: 84.13vw;
        margin-left: 5.33vw;
        margin-top: 2.53vw;
      }
    }
    &.school {
      margin: 0 3.06vw 0 8vw;
      + ${RCInput} {
        width: 66.8vw;
        margin-top: 3.99vw;
      }
    }
    &.major {
      margin: 0 3.06vw 0 8vw;
      + ${RCInput} {
        width: 66.8vw;
        margin-top: 3.99vw;
      }
    }
    &.info-from {
      margin: 0 0 0 5.33vw;
      + ${RCRadioContainer} {
        margin: unset;
        padding-left: 7.06vw;
        width: 92.94vw;
        font-size: 2.39vw;
        > div {
          width: 35vw;
        }
        span {
          &::before {
          }
          &::after {
          }
        }
        .other-input {
          width: 26.8vw;
        }
      }
    }
  }
`
export const Billboard2 = styled(Img)`
  height: auto;
`
export const RCHeader = styled.div`
  background: linear-gradient(180deg, #0d276d 0%, #061438 100%);
  display: flex;
  align-items: center;
  height: 62px;
  user-select: none;
  z-index: 10;
  justify-content: center;
  ${Media.lessThan(Media.small)} {
    position: fixed;
    top: 0px;
    width: 100vw;
    max-width: 750px;
    height: 12vw;
  }
`
export const RCHeaderContainer = styled.div`
  position: absolute;
  top: 0;
`
export const PreviousContain = styled.div`
  display: none;
  position: absolute;
  top: 70px;
  left: 40px;
  min-width: 130px;
  height: 109px;
  background: #0f318a;
  padding: 10px;

  a {
    cursor: pointer;
    display: block;
    font-size: 14px;
    line-height: 33px;
    color: #ffffff;
    margin-bottom: 15px;
  }
  a:hover {
    background: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
  }
`
export const HeaderLogo = styled.div`
  background: url(${HeaderLogoImg}) no-repeat;
  background-size: 100%;
  min-width: 142px;
  min-height: 48px;
  margin-left: 18.8vw;
  ${Media.lessThan(Media.small)} {
    min-width: 22.83vw;
    min-height: 6.8vw;
    margin-left: 3.46vw;
  }
`
export const HeaderItem = styled.a`
  position: relative;
  color: #fff;
  min-width: 200px;
  height: 100%;
  line-height: 62px;
  text-align: center;
  z-index: 3;
  img {
    margin-left: 5px;
  }
  &:hover {
    ::before {
      display: inline-block;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        272deg,
        rgba(61, 115, 255, 0) 0%,
        #2562ff 51%,
        rgba(61, 115, 255, 0) 100%
      );
      opacity: 0.3;
      left: 0;
      z-index: -1;
    }
    :after {
      display: inline-block;
      content: '';
      background: url(${NavStrip}) no-repeat;
      position: absolute;
      width: 93px;
      height: 7px;
      left: 0;
      right: 0;
      bottom: 11px;
      margin: auto;
      z-index: 2;
    }
  }
  &.previous-item {
    :hover {
      ${PreviousContain} {
        display: block;
      }
    }
    .interval {
      width: 100%;
      height: 8px;
      background-color: rgba(255, 255, 255, 0);
    }
  }
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`
export const RCTitle = styled.div`
  color: #ffffff;
  p {
    font-size: 2.67vw;
    font-weight: 400;
    margin: 0;
  }
  p:first-child {
    font-size: 3.2vw;
    font-weight: 700;
  }
`
export const SignButton = styled.a`
  color: #17101b;
  font-size: 16px;
  font-weight: 700;
  min-width: 150px;
  max-height: 40px;
  line-height: 40px;
  text-align: center;
  background: linear-gradient(225deg, #e1f0ff 0%, #ebf5ff 0%, #4fa2f0 81%, #f4d096 100%);
  border: 1px solid #ffffff;
  border-radius: 87px;
  margin: 0 25px;
  :hover {
    background: linear-gradient(225deg, #f4d096 0%, #4fa2f0 0%, #ebf5ff 81%, #e1f0ff 100%);
  }
  &.side {
    display: none;
  }
  ${Media.lessThan(Media.small)} {
    display: none;
    margin: 0 0 0 3.43vw;
    min-width: 28vw;
    max-height: 8vw;
    line-height: 8vw;
    font-size: 3.2vw;
    border-radius: 3vw;
    &.side {
      margin: 0;
      display: block;
    }
  }
`
export const SignUpButton = styled.a`
  background: url(${SignBtnBg});
  width: 165px;
  height: 45px;
  font-size: 16px;
  font-weight: 700;
  color: #17101b;
  text-align: center;
  line-height: 47px;
  margin: 8px 0 0px -40px;
  :hover {
    background: url(${DownloadBtnHover});
    background-size: 100% 100%;
  }
`

export const HistoryMatchItem = styled.a`
  display: block;
  width: ${mbSize(232)};
  height: ${mbSize(66)};
  border-radius: 100px;
  background: rgba(255, 255, 255, 0.1);
  font-size: ${mbSize(24)};
  color: #ffffff;
`

export const HistoryMatchDropDown = styled.div`
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, 100%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: ${mbSize(264)};
  height: ${mbSize(212)};
  background: #091a4c;
  padding: ${mbSize(24)} ${mbSize(16)};
`

export const HistoryMatch = styled.div`
  display: none;
  position: relative;
  color: #17101b;
  font-size: 16px;
  font-weight: 700;
  min-width: 150px;
  max-height: 40px;
  line-height: 40px;
  text-align: center;
  background: linear-gradient(225deg, #e1f0ff 0%, #ebf5ff 0%, #4fa2f0 81%, #f4d096 100%);
  border: 1px solid #ffffff;
  border-radius: 87px;
  margin: 0 25px;
  :hover {
    background: linear-gradient(225deg, #f4d096 0%, #4fa2f0 0%, #ebf5ff 81%, #e1f0ff 100%);
  }
  ${Media.lessThan(Media.small)} {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 3.43vw;
    min-width: 28vw;
    max-height: 8vw;
    line-height: 8vw;
    font-size: 3.2vw;
    border-radius: 63px;
    .arrow {
      border-left: 2px solid #17101b;
      border-bottom: 2px solid #17101b;
      width: 8px;
      height: 8px;
      margin: 0 0 5px 5px;
      transform: rotate(-45deg);
    }
  }
  ${HistoryMatchDropDown} {
    display: none;
  }
  ${(p) =>
    p.active &&
    css`
      .arrow {
        margin: 0 0 -2px 5px;
        transform: rotate(135deg);
      }
      ${HistoryMatchDropDown} {
        display: flex;
      }
    `}
`

export const BackToTop = styled.a`
  font-size: 14px;
  color: #2a7cca;
  margin: 0 0 30px -40px;
  ::before {
    display: block;
    content: '';
    width: 23px;
    height: 23px;
    margin: auto;
    background: url(${BackTop});
  }
  ${Media.lessThan(Media.small)} {
    margin: 0;
  }
`
export const QrContainer = styled.div`
  width: 122px;
  height: 122px;
  margin-top: 16px;
  background: url(${QrBg});
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    margin: 0;
    color: #fff;
    font-size: 14px;
    margin: 4px 0 0 0;
  }
`
export const NavigateMenuIcon = styled.div`
  width: 3.2vw;
  height: 3.2vw;
  margin-left: ${(p) => '8vw'};
  transition: ${transition('transform')};
  background: ${(p) => (p.isActive ? `url(${MenuIcon})` : `url(${CloseIcon})`)};
  background-size: 100% 100%;
  ${hideSMUp}
`
export const NavigateMenuItem = styled.a`
  color: ${(p) => (p.isActive ? '#FFFFFF' : '#7EBBF5')};
  font-weight: bold;
  border-radius: 20px;
  width: 147px;
  height: 32px;
  line-height: 32px;
  margin-top: 12px;
  text-align: center;
  background: ${(p) => (p.isActive ? `url(${NavItemBg})` : 'unset')};
  margin-left: -40px;
  &:hover {
    background: url(${NavItemBg});
    color: #ffffff;
  }
  ${Media.lessThan(Media.small)} {
    text-align: center;
    margin: 0 auto;
    width: 28.53vw;
    height: 8.53vw;
    font-size: 3.73vw;
    font-weight: 400;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
`
export const NavigateMenu = styled.div`
  position: fixed;
  user-select: none;
  display: ${(p) => (p.showPCMenu ? 'block' : 'none')};
  left: 1.61vw;
  top: 50vh;
  transform: translate(0, -50%);
  z-index: 1;
  .menu-container {
    display: ${(p) => (p.showPCMenu ? 'flex' : 'none')};
    width: 160px;
    height: 598px;
    flex-direction: column;
    align-items: center;
    background: url(${SidebarBg});
    justify-content: flex-end;
  }
  ${Media.lessThan(Media.small)} {
    display: ${(p) => (p.isActive ? 'flex' : 'none')};
    position: absolute;
    width: 32vw;
    padding: 2.13vw 0;
    left: 98vw;
    top: 0;
    transform: translate(-100%, 12vw);
    .menu-container {
      display: ${(p) => (p.isActive ? 'flex' : 'none')};
      width: 32vw;
      height: unset;
      flex-direction: column;
      align-items: center;
      background-image: unset;
      justify-content: end;
      background: linear-gradient(180deg, #071a4d 0%, #121d47 100%),
        linear-gradient(180deg, #0d276d 0%, #061438 100%);
      border-radius: 2px;
      padding: 8px 0;
    }
  }
`
export const RCOrganization = styled.div`
  /* position: absolute; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #ffffff;
  font-size: 18px;
  p {
    margin: unset;
  }
  .banner-content {
    /* position: absolute; */
    width: max-content;
    margin: 23% auto 0;
    position: relative;
    .title {
      position: absolute;
      left: 150px;
      top: 88px;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 4px;
    }
  }
  ${Media.lessThan(Media.small)} {
    left: unset;
    bottom: 24vw;
    padding-left: 4.4vw;
    font-size: 2.66vw;
    .banner-content {
      margin: 49.6vw auto 0;
      .title {
        font-size: 14px;
        letter-spacing: 2px;
        left: 0;
        top: 6.6vw;
      }
      img {
        width: 94.66vw;
        height: 53.86vw;
      }
    }
  }
`
export const AwardTotal = styled.div`
  text-align: center;
  margin: 20px auto 60px;
  p {
    margin: 0 auto;
    color: #ffd69b;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 3px;
  }
  .total-num {
    font-family: 'Roboto-Regular';
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
    letter-spacing: 8px;
    background: linear-gradient(180deg, #ffd69b 49%, #925802 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    position: relative;
    width: 864px;
    &::after {
      display: block;
      content: '';
      position: absolute;
      background: url(${awardTotalBg});
      background-size: 100% 100%;
      width: 864px;
      height: 76px;
      top: 0;
    }
  }
  ${Media.lessThan(Media.small)} {
    margin: 20px auto 30px;
    p {
      margin: 0 auto;
      font-size: 3.2vw;
    }
    .total-num {
      font-size: 5.33vw;
      width: 91.46vw;
      letter-spacing: 4px;
      line-height: 6.13vw;
      &::after {
        display: block;
        content: '';
        position: absolute;
        background: url(${awardTotalBgMb});
        background-size: 100% 100%;
        width: 91.46vw;
        height: 10.13vw;
        top: 0;
      }
    }
  }
`

export const MainJudges = styled.div`
  display: flex;
  margin: 60px auto 100px;
  gap: 40px;
  ${Media.lessThan(Media.small)} {
    margin: 16vw auto 8vw;
    flex-wrap: wrap;
    gap: 3.46vw;
    justify-content: center;
  }
`

export const MainJudgeItem = styled.div`
  position: relative;
  width: 270px;
  height: 428px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 30px 16px;
  .avatar {
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
  }
  .icon-more {
    width: 16px;
  }
  p {
    margin: 0;
    color: #ffffff;
  }
  .name {
    font-weight: 700;
    line-height: 32px;
    font-size: 20px;
  }
  .english-name,
  .intro {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
  ${Media.lessThan(Media.small)} {
    width: 44vw;
    height: 68.88vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 10px;
    &.andong-detail {
      justify-content: center;
    }
    .avatar {
      width: 44vw;
      height: 68.88vw;
    }
    .icon-more {
      width: 10px;
    }
    p {
      margin: 0;
      color: #ffffff;
    }
    .name {
      font-weight: 700;
      line-height: 5.33vw;
      font-size: 3.2vw;
    }
    .english-name,
    .intro {
      font-size: 2.67vw;
      line-height: 4.8vw;
      margin-bottom: 2px;
    }
  }
`

export const ResultsAnnouncedWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  ${Media.lessThan(Media.small)} {
    margin-top: ${mbSize(100)};
  }
`

export const RankAnnouncedWrap = styled.div`
  display: flex;
  align-items: end;
  gap: 28px;
  ${Media.lessThan(Media.small)} {
    align-items: start;
    gap: ${mbSize(26)};
  }
`

export const RankWorkInfoWrap = styled.div`
  width: 783px;
  height: ${({ hasHeader }) => (hasHeader ? '596px' : '538px')};
  margin-top: ${({ hasHeader }) => (hasHeader ? '0px' : '58px')};
  background: #07112a;
  padding: 4px 8px 10px;
  ${Media.lessThan(Media.small)} {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${mbSize(540)};
    height: fit-content;
    padding: ${mbSize(2)} ${mbSize(3)} ${mbSize(10)};
    margin-top: ${({ hasHeader }) => (hasHeader ? 0 : mbSize(46))};
  }
`

export const RankWorkInfoHeader = styled.div`
  width: 100%;
  height: 54px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  color: #7cb9fd;
  margin-bottom: 4px;
  ${Media.lessThan(Media.small)} {
    height: ${mbSize(38)};
    font-size: ${mbSize(20)};
  }
`

export const RankWorkButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 54px;
  background: ${({ disabled }) => (disabled ? '#091B47' : '#0a276d')};
  border: unset;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-weight: 400;
  font-size: 16px;
  color: #7cb9fd;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(102)};
    height: ${mbSize(38)};
    font-size: ${mbSize(20)};
  }
`

export const RankContent = styled.div`
  width: 767px;
  height: 524px;
  border: 1px solid;
  border-image: linear-gradient(225deg, rgba(244, 208, 150, 1), rgba(124, 184, 253, 1) 20%) 1;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(528)};
    height: fit-content;
  }
`

export const RankVideo = styled.div`
  width: 764px;
  height: 431px;
  cursor: pointer;
  video {
    width: 100%;
    height: 100%;
  }
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(524)};
    height: ${mbSize(298)};
  }
`

export const RankInfoWrap = styled.div`
  height: 93px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  ${Media.lessThan(Media.small)} {
    padding: ${mbSize(12)} 0 ${mbSize(20)} ${mbSize(20)};
    font-size: ${mbSize(20)};
    gap: ${mbSize(8)};
    flex-direction: column;
    align-items: start;
    height: fit-content;
  }
`

export const RankInfoLeftWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  > div:nth-child(1) {
    font-size: 16px;
    font-weight: 500;
  }
  ${Media.lessThan(Media.small)} {
    gap: ${mbSize(8)};
    font-size: ${mbSize(20)};
    > div:nth-child(1) {
      font-size: ${mbSize(24)};
    }
  }
`

export const RankInfoDesc = styled.div`
  font-weight: 500;
  font-size: 20px;
  color: #7cb9fd;
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(20)};
  }
`

export const RankInfoSidebar = styled.div`
  height: 534px;
  width: 161px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(110)};
    height: ${mbSize(432)};
    gap: ${mbSize(26)};
    margin-top: ${mbSize(46)};
  }
`

export const RankInfoSidebarItem = styled.div`
  position: relative;
  width: 161px;
  height: 90px;
  border: 1px solid;
  border-image: linear-gradient(
      271deg,
      rgba(244, 208, 150, 0),
      ${({ active }) => (active ? '#7CB9FD' : 'rgba(124, 184, 253, 0.4)')}
    )
    1 1;
  transition: all 0.3s;
  cursor: pointer;
  background: url(${({ background }) => background}) #010613b3;
  background-blend-mode: multiply;
  background-clip: content-box;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ active }) =>
    active &&
    css`
      width: 209px;
      height: 117px;
      transform: translateX(-48px);
    `}
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(110)};
    height: ${mbSize(62)};
    ${({ active }) =>
      active &&
      css`
        width: ${mbSize(144)};
        height: ${mbSize(80)};
        transform: translateX(${mbSize(-34)});
      `}
  }
`

export const RankInfoSidebarItemName = styled.div`
  background: url(${({ background }) => background});
  padding: 0 20px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: ${({ color }) => color};
  height: ${({ active }) => (active ? '23px' : '18px')};
  font-weight: 300;
  font-size: ${({ active }) => (active ? '22px' : '16px')};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  ${Media.lessThan(Media.small)} {
    font-weight: 300;
    font-size: ${mbSize(16)};
    height: ${mbSize(13)};
    padding: 0 ${mbSize(12)};
  }
`

export const AwardsWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 54px;
  margin: 167px 0 170px;
  ${Media.lessThan(Media.small)} {
    margin: ${mbSize(100)} 0 ${mbSize(140)};
    gap: ${mbSize(30)};
    align-items: start;
  }
`

export const AwardsSidebar = styled.div`
  display: flex;
  align-items: center;
  gap: 59px;
  height: 478px;
  ${Media.lessThan(Media.small)} {
    height: fit-content;
    margin-top: ${mbSize(46)};
  }
`

export const AwardsScrollBar = styled.div`
  background: url(${({ background }) => background});
  width: 45px;
  height: 478px;
  background-size: 100% 100%;
  position: relative;
  ${Media.lessThan(Media.small)} {
    display: none;
  }
`

export const AwardsScrollBarItem = styled.div`
  width: 6px;
  height: 6px;
  background: rgba(187, 236, 255, 0.6);
  border-radius: 50%;
  position: absolute;
  transition: all 0.3s;
  top: ${({ top }) => `${top}px`};
  left: ${({ left }) => `${left}px`};
  ${({ active }) =>
    active &&
    css`
      background: url(${ScrollPointHover});
      background-size: 100% 100%;
      width: 30px;
      height: 30px;
      top: ${({ topActive }) => `${topActive}px`};
      left: ${({ leftActive }) => `${leftActive}px`};
    `}
`

export const AwardsSidebarItemsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: 478px;
  position: relative;
  width: 231px;
  align-items: end;
  ${Media.lessThan(Media.small)} {
    height: fit-content;
    width: ${mbSize(100)};
    gap: ${mbSize(26)};
  }
`

export const AwardsSidebarItem = styled.div`
  width: 171px;
  height: 90px;
  border: 1px solid;
  border-image: linear-gradient(
      270deg,
      ${({ active }) => (active ? '#7CB9FD' : 'rgba(124, 184, 253, 0.4)')},
      rgba(244, 208, 150, 0)
    )
    1 1;
  background: url(${({ background }) => background}) #010613b3;
  background-blend-mode: multiply;
  background-clip: content-box;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  transform: translateX(${({ translateX }) => `${translateX}px`});
  z-index: 1;
  ${({ active }) =>
    active &&
    css`
      transform: translateX(${({ translateX }) => `${translateX}px`});
      width: 231px;
      height: 130px;
    `}
  ${Media.lessThan(Media.small)} {
    height: ${mbSize(56)};
    width: ${mbSize(100)};
    transform: translateX(0);
    font-size: ${mbSize(16)};
    ${({ active }) =>
      active &&
      css`
        transform: translateX(${mbSize(44)});
        width: ${mbSize(144)};
        height: ${mbSize(80)};
      `}
  }
`

export const AwardsSidebarItemName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  background: url(${({ background }) => background});
  background-size: 100% 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0 15px;
  font-weight: 300;
  font-size: 16px;
  color: #7cb9fd;
  ${({ active }) =>
    active &&
    css`
      height: 29px;
      font-weight: 400;
      font-size: 20px;
    `}
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(16)};
    font-weight: 300;
    padding: 0 ${mbSize(10)};
    text-align: center;
    ${({ active }) =>
      active &&
      css`
        font-weight: 400;
      `}
  }
`

export const AwardsInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 8px;
  width: 783px;
  height: 596px;
  background: #07112b;
  ${Media.lessThan(Media.small)} {
    width: ${mbSize(540)};
    height: fit-content;
    padding: ${mbSize(2)} ${mbSize(6)} ${mbSize(6)};
  }
`

export const AwardsInfoHeader = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  gap: 35px;
  background: #07112b;
  width: 100%;
  ${Media.lessThan(Media.small)} {
    gap: 0;
  }
`

export const AwardsInfoHeaderItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  color: #7cb9fd;
  width: 165px;
  height: 67px;
  cursor: pointer;
  ${({ active }) =>
    active &&
    css`
      background: url(${AwardsselectedBg});
      background-size: 100% 100%;
      background-repeat: no-repeat;
    `}
  ${Media.lessThan(Media.small)} {
    font-size: ${mbSize(14)};
    width: ${mbSize(133)};
    height: ${mbSize(44)};
  }
`
